import {Text} from "@react-gcc-eds/core/";
import logo from '../Assets/planet.jpg'; // with import


/*
<Text size="xl">More Performance, more sustainability, more possibilites. <br/>Innovation that never stops!</Text>
*/
import React, { PureComponent } from 'react'
export default class ImageTitel extends PureComponent {
  render() {
    return (
        <div className = "ImageTitel">
          <div class="image-container">
            <img src={logo} alt="Planet"/>
          </div>
          <div className="left">
            <h1 className="title-xl">Ericsson <br/>Garage <br/> Lindholmen</h1>
            <br/>
            <br/>
            <h1 className="title-l">Innovation that never stops!</h1>
          </div>
      </div>
    )
  }
}
